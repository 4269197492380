.columns {
  display: flex;
  flex-direction: column;

  &--large {
    margin-right: 64px;
    width: 67%;

    @include viewport--lg {
      width: 100%;
    }
  }

  &--small {
    width: 40%;

    @include viewport--lg {
      width: 100%;
    }
  }

  label {
    pointer-events: none;
  }
}

.columns__input-wrap {
  margin: 0;
  margin-bottom: 35px;

  @include viewport--lg {
    margin-bottom: 16px;
  }
}

.columns__input-wrap input {
  padding: 0;
  padding-bottom: 20px;
  width: 100%;

  font-size: 18px;
  line-height: 20px;
  // background: $razzmatazz-to-purple-heart;
  // -webkit-background-clip: text;
  // -webkit-text-fill-color: transparent;
  color: $color-dark-magenta;

  border: 0;
  border-bottom: 3px solid $color-dark-magenta;
  outline: none;

  // для iphone 5/se
  @media (max-width: 350px) {
    padding-bottom: 10px;
    font-size: 16px;
    line-height: 18px;
  }

  &::placeholder {
    background: $razzmatazz-to-purple-heart;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    opacity: 0.5;
    transition: opacity $default-transition-settings;
  }

  &:hover::placeholder,
  &:focus::placeholder,
  &:active::placeholder {
    background: $razzmatazz-to-purple-heart;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    transition: opacity $default-transition-settings;
    opacity: 1;
  }
}

.columns textarea {
  margin: 0;
  margin-bottom: 35px;
  padding: 0;

  // для firefox transition
  width: 100%;
  height: 123px;

  font-size: 18px;
  line-height: 20px;
  // background: $razzmatazz-to-purple-heart;
  // -webkit-background-clip: text;
  // -webkit-text-fill-color: transparent;
  color: $color-dark-magenta;

  border: 0;
  border-bottom: 3px solid $color-dark-magenta;
  outline: none;

  overflow: auto;
  pointer-events: auto;

  @include viewport--lg {
    margin-bottom: 16px;
    padding-bottom: 20px;
    height: 72px;
  }

  // для iphone 5/se
  @media (max-width: 350px) {
    height: 50px;
    font-size: 16px;
    line-height: 18px;
  }

  &::placeholder {
    background: $razzmatazz-to-purple-heart;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    opacity: 0.5;
    transition: opacity $default-transition-settings;
  }

  &:hover::placeholder,
  &:focus::placeholder,
  &:active::placeholder {
    background: $razzmatazz-to-purple-heart;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    transition: opacity $default-transition-settings;
    opacity: 1;
  }
}
