.footer {
  width: 100%;

  position: fixed;
  bottom: 66px;
  padding-left: 94px;
  padding-right: 6.3%;
  z-index: 200;

  @include viewport--lg {
    padding-left: 65px;
  }

  @include viewport--md {
    width: 87%;
    min-height: 66px;
    padding-right: 0;
    padding-left: 0;
    right: 6.3%;
    bottom: 28px;

    opacity: 0;
    animation: comming-footer 2s 2s forwards;
  }

  // для iphone 5/se
  @media (max-width: 350px) {
    bottom: 10px;
  }
}

.footer__box {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
}

@keyframes comming-footer {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
