.header {
  padding: 20px 0;
  display: flex;

  position: fixed;
  top: 0;
  right: 6.3%;
  z-index: 200;

  @include viewport--md {
    min-height: 88px;
  }

  @include viewport--md {
    &.disabled {
      display: none;
    }
  }
}

.header__logo {
  width: 205px;
  min-height: 48px;
  margin-left: auto;

  display: inline-block;
  vertical-align: middle;

  position: relative;
  transition: $default-transition-settings;

  &:hover,
  &:focus {
    opacity: 0.5;
  }

  &.hover-disabled {
    pointer-events: none;
    opacity: 1;

    @include viewport--md {
      opacity: 0;
    }
  }

  .img-white {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 20;

    opacity: 0;
    transition: opacity $default-transition-settings;
  }

  .img-gradient {
    position: absolute;
    top: 0;
    left: 0;

    opacity: 0;
    transition: opacity $default-transition-settings;
  }

  .img-white-active {
    opacity: 1;

    @include viewport--md {
      &.disabled {
        opacity: 0;
      }
    }
  }

  .img-gradient-active {
    opacity: 1;

    @include viewport--md {
      &.disabled {
        opacity: 0;
      }
    }
  }

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    width: 205px;
    height: 48px;
    background: transparent;
    cursor: pointer;
    z-index: 2;
  }

  @include viewport--md {
    position: absolute;
    top: 44.3%;
    right: 23.3%;

    opacity: 0;
    animation-name: transform-logo;
    animation-duration: 2s;
    // до начала воспроизведения 2s
    animation-delay: 2s;
    /* после завершения анимации состояние элемента
    будет соответствовать последнему кадру */
    animation-fill-mode: forwards;
  }
}

@keyframes transform-logo {
  0% {
    opacity: 0;
  }

  100% {
    top: 20px;
    right: 0;
    opacity: 1;
  }
}
