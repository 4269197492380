.mobile-menu {
  display: none;

  @include viewport--md {
    position: fixed;
    top: 0;
    right: 0;

    //- без этого не работает transition
    display: flex;
    align-items: flex-end;

    height: 100vh;
    min-height: calc(100 * var(--vh, 1vh));
    width: 100%;

    background-color: $color-default-white;

    transform: translateX(100%);
    transition: transform $default-transition-settings;
    transition-delay: 0.4s;

    overflow-y: auto;
    z-index: 1000;
  }
}

.mobile-menu.active {
  @include viewport--md {
    position: fixed;
    top: 0;
    right: 0;

    display: flex;
    align-items: flex-end;

    width: 100%;
    height: 100vh;
    min-height: calc(100 * var(--vh, 1vh));

    transform: translateX(0);
    transition: transform $default-transition-settings;
    transition-delay: 0.3s;

    background-color: $color-default-white;

    overflow-y: auto;
    z-index: 1000;
  }
}

.mobile-menu__box {
  position: relative;
  height: 100%;
  width: 100%;

  display: flex;
  align-items: flex-end;

  .mobile-menu__btn-box {
    position: absolute;
    top: 23px;
    right: 6%;
  }
}

.mobile-menu__list {
  @include viewport--md {
    list-style: none;
    margin: 0;
    padding: 0;
    padding-right: 25px;
    padding-bottom: 25px;

    width: 100%;
    //для ios
    min-height: 318px;

    display: flex;
    flex-direction: column;
    align-items: flex-end;
    text-align: right;

    li:not(:last-child) {
      margin-bottom: 20px;
    }

    .btn--gradient-link {
      margin-bottom: 20px;
      font-size: 32px;
      line-height: 45px;
      opacity: 1;
    }
  }
}
