.apps {
  @include keyframes-fadeIn;
  @include keyframes-fadeOut;
  @include keyframes-transform;

  position: relative;
}

.apps__content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;

  @include viewport--md {
    min-height: 457px;
  }

  @include viewport--sm {
    min-height: 336px;
  }

  .blocks-btn {
    @include viewport--md {
      // position: absolute;
      display: inline-block;
      // order: 1;
      // top: 58%;
      // left: calc(50% - 239px / 2);
      margin: 0 auto;
      opacity: 0;
      animation: $opacity-pages-delay-3s;
    }

    // @media (max-width: 350px) {
    //   top: 53%;
    //   left: calc(50% - 220px / 2);
    // }
  }

  .blocks-btn-disabled {
    display: none;

    @include viewport--md {
      display: inline-block;
    }
  }

  .page__title {
    transform: translateY(162px);
    animation: translate-apps-title 2.5s 2s forwards;

    @keyframes translate-apps-title {
      0% {
        transform: translateY(162px);
      }

      100% {
        transform: translateY(0);
      }
    }

    @keyframes translate-apps-title-2 {
      0% {
        transform: translateY(0);
      }

      100% {
        transform: translateY(162px);
      }
    }

    @include viewport--md {
      transform: translateY(0);
      animation: none;
    }

    &--fade-in {
      transform: translateY(0);
      animation: translate-apps-title-2 4s forwards;
    }
  }

  .page__text {
    animation: fade-out 1s 2s forwards;

    &--fade-in {
      animation: fade-in 2s forwards;
    }
  }
}

.apps__info {
  margin-top: -44px;

  @include viewport--md {
    margin-top: 23px;
  }

  @include viewport--sm {
    margin-top: 0;
  }
}

.apps__text {
  width: 48%;
  margin: 0;
  padding-left: 1%;
  padding-right: 15px;
  font-size: calc(12px + 1.06vw);
  line-height: calc(17px + 1vw);
  color: $color-default-white;
  opacity: 0;
  animation: fade-out 1s 2s forwards;

  @media (max-width: 900px) {
    width: 56%;
  }

  @media (min-width: 1680px) {
    width: 56%;
  }

  @include viewport--md {
    width: 100%;
    margin-bottom: 39px;
    padding: 0;
    font-size: 24px;
    line-height: 28px;
    opacity: 0;
    animation: $opacity-pages;
  }

  @include viewport--sm {
    font-size: 18px;
    margin-top: 0;
  }

  // для iphone 5/se
  @media (max-width: 350px) {
    margin-top: 15px;
    margin-bottom: 30px;
    font-size: 16px;
    line-height: 18px;
  }
}

.apps__title {
  position: relative;
  margin: 0 0 48px;
  font-size: calc(94px + 2.5vh + 2.5vw);
  line-height: calc(119px + 6vh);
  font-weight: 400;
  color: $color-default-white;
  -webkit-text-stroke: 3px $color-default-white;
  text-transform: lowercase;
  z-index: 2;

  @include viewport--lg {
    @media (max-height: 699px) {
      line-height: 23vh;
      font-size: calc(22px + 16.5vh);
    }

    @media (max-height: 579px) {
      line-height: 20vh;
    }
  }

  @media (max-width: 1060px) {
    font-size: calc((100vw - 768px) / (1152 - 768) * (140 - 94) + 94px);
  }

  @include viewport--md {
    margin-bottom: 26px;
    font-size: 48px;
    line-height: 65px;
    letter-spacing: 1.5px;
    transform: translateY(0);
    animation: none;
  }

  @media (max-width: 400px) {
    -webkit-text-stroke: 1.5px $color-default-white;
  }

  @include viewport--sm {
    top: -12px;
    font-size: 40px;
    margin-bottom: 0;
  }

  // для iphone 5/se
  @media (max-width: 350px) {
    top: 0;
    font-size: 30px;
  }
}

.apps__img {
  position: absolute;
  width: auto;
  height: 72vh;
  top: 4.6%;
  left: 43.2%;
  z-index: 1;
  animation: fade-out 1s forwards;

  @include viewport--lg {
    width: 51.3%;
    height: auto;
  }

  @media (max-width: 900px) {
    width: 465px;
    left: 40%;
  }

  @include viewport--md {
    width: 199px;
    top: 80.8%;
    left: 0;
    right: 0;
    margin: auto;
    opacity: 0;
    transform: translateY(-90px) scale(2);
    animation: $opacity-pages-delay-1s, $transform-pages;
  }

  // для iphone 5/se
  @media (max-width: 350px) {
    top: 75%;
  }
}
