.error {
  @include keyframes-fadeIn;
  // @include keyframes-transform;
  position: relative;
  background: $razzmatazz-to-purple-heart;
  //- для ios
  min-height: 100vh;
  min-height: calc(100 * var(--vh, 1vh));
  overflow-y: hidden;

  .container {
    width: 100%;
    max-width: 1152px;
    min-height: 100vh;
    min-height: calc(100 * var(--vh, 1vh));
    //- для ios плавный рейсайз
    transition: min-height $default-transition-settings;
  }
}

.error__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: -28vh;
  margin-left: -20px;
  overflow-y: hidden;

  @media (max-width: 900px) {
    padding-top: 30px;
  }
}

.error__text {
  position: relative;
  width: 70%;
  margin: 0;
  font-size: calc(4px + 1.09vh + 1.08vw);
  line-height: calc(10px + 1vh + 1vw);
  color: $color-default-white;
  text-align: center;
  z-index: 2;

  @media (max-width: 900px) {
    width: 100%;
  }

  @include viewport--md {
    width: 98%;
    padding: 0;
    opacity: 0;
    animation: $opacity-pages;
  }

  @include viewport--sm {
    font-size: 22px;
  }
}

.error__title {
  margin: 0 0 14px;
  font-size: calc(94px + 2.5vh + 2.5vw);
  line-height: calc(119px + 6vh);
  font-weight: 400;
  color: $color-default-white;
  -webkit-text-stroke: 3px $color-default-white;
  text-transform: lowercase;

  @include viewport--lg {
    font-size: calc((100vw - 768px) / (1152 - 768) * (140 - 94) + 94px);
  }

  @include viewport--md {
    margin-bottom: 25px;
    font-size: 48px;
    line-height: 65px;
    letter-spacing: 1.5px;
  }

  @include viewport--sm {
    font-size: 42px;
  }
}

.error__img {
  position: absolute;
  width: 857px;
  height: auto;
  left: calc(50.3% - 857px / 2);
  bottom: 0;
  z-index: 1;

  @media (max-width: 900px) {
    width: 768px;
    left: 0;
    right: 0;
    margin: auto;
  }

  @include viewport--md {
    opacity: 0;
    transform: scale(1.5);
    animation: $opacity-pages, transform-error 4s 0.5s forwards;
  }
}

@keyframes transform-error {
  0% {
    transform: scale(1.5);
  }

  100% {
    transform: scale(1);
  }
}
