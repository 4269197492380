.container {
  position: relative;
  margin: 0 auto;
  padding: 88px 73px 67px 94px;

  // width: 100%;
  // max-width: 1152px;
  min-height: 100vh;
  min-height: calc(100 * var(--vh, 1vh));
  //- для ios плавный рейсайз
  // transition: min-height $default-transition-settings;

  display: flex;
  flex-direction: column;
  justify-content: center;
  // растягивает контент при большем размере экрана
  // @media (min-width: 1298px) {
  //   min-width: 1152px;
  //   max-width: 100%;
  // }

  @include viewport--lg {
    padding: 88px 65px 67px;
  }

  @include viewport--md {
    padding: 0 25px;
    padding-bottom: 40px;
  }
}

.container__content {
  display: flex;
  flex-direction: row;
  // justify-content: space-between;
  width: 100%;
  // padding-top: 88px;
  // padding-bottom: 88px;
}

.container__content--active {
  display: flex;
  opacity: 0;
  animation: fade-out 3s 1s forwards;
  transition: $default-transition-settings;
}

.container__content--fade-in {
  @include keyframes-fadeIn;
  opacity: 1;
  animation: fade-in 4s forwards;
  transition: $default-transition-settings;
}

.container__content--shift {
  display: none;
}

@include keyframes-fadeOut;
@include keyframes-fadeIn;
