.btn {
  text-align: center;

  border: none;
  border-radius: 0;
  outline: none;
  text-transform: lowercase;
  background: transparent;

  cursor: pointer;
}

.btn--white-link {
  font-size: 18px;
  line-height: 21px;

  min-width: 56px;
  min-height: 21px;

  color: $color-default-white;
  opacity: 0.5;
  pointer-events: auto;

  transition: opacity $default-transition-settings;

  &:hover,
  &:focus {
    opacity: 1;
    box-shadow: none;
  }

  &:active {
    line-height: 20px;
    color: $color-default-white;
    border-bottom: 1px solid $color-default-white;
    box-shadow: none;
  }

  @include viewport--md {
    opacity: 1;
  }
}

.btn--white-link-active {
  font-size: 18px;
  line-height: 21px;

  min-width: 56px;
  min-height: 21px;

  color: $color-default-white;

  border-radius: 0;
  border-bottom: 1px solid $color-default-white;
  background: transparent;
  opacity: 1;

  pointer-events: none;

  @include viewport--md {
    pointer-events: auto;
  }
}

.btn--gradient-link {
  font-size: 18px;
  line-height: 21px;

  min-width: 56px;
  min-height: 21px;

  color: $color-default-white;

  border-radius: 0;
  background: $razzmatazz-to-purple-heart;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  opacity: 0.5;
  transition: opacity $default-transition-settings;

  &:hover,
  &:focus {
    min-height: 21px;
    opacity: 1;
  }

  &:active {
    background: $razzmatazz-to-purple-heart;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    border-bottom: 1px solid;
    border-image: $razzmatazz-to-purple-heart;
    border-image-slice: 1;
  }
}

.btn--gradient-link-active {
  font-size: 18px;
  line-height: 21px;

  min-width: 56px;
  min-height: 21px;

  border-radius: 0;
  background: $razzmatazz-to-purple-heart;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  border-bottom: 1px solid;
  border-image: $razzmatazz-to-purple-heart;
  border-image-slice: 1;

  pointer-events: none;
}

.btn--burger {
  display: none;

  @include viewport--md {
    padding: 0;
    margin-left: auto;

    position: relative;
    display: block;
    order: 2;

    width: 52px;
    height: 52px;

    background: transparent;
    opacity: 1;
    transition: opacity $default-transition-settings;

    &::after {
      position: absolute;
      bottom: -2px;
      left: 0;

      content: "______ ______ ______ ______ ______ ______";
      width: 52px;
      height: 36px;
      color: $color-default-white;
      line-height: 4px;
      opacity: 0.5;
    }

    &:hover,
    &:focus,
    &:active {
      opacity: 0.8;
    }
  }
}

.btn--burger-gradient {
  position: relative;
  opacity: 1;

  &::after {
    height: 36px;

    background: $razzmatazz-to-purple-heart-transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    opacity: 1;
  }

  &:hover,
  &:focus,
  &:active {
    opacity: 0.8;
  }
}

.btn--burger-close {
  width: 52px;
  height: 52px;

  position: relative;
  opacity: 1;
  transition: opacity $default-transition-settings;
  background: transparent;

  &::after {
    position: absolute;
    top: 26px;
    left: -0.5px;

    content: "";
    width: 52px;
    height: 1px;
    background-color: $color-dark-magenta;
    opacity: 1;

    transform: rotate(45deg);
  }

  &::before {
    position: absolute;
    top: 26px;
    left: -0.5px;

    content: "";
    width: 52px;
    height: 1px;
    background-color: $color-dark-magenta;
    opacity: 1;

    transform: rotate(-45deg);
  }

  &:hover,
  &:focus,
  &:active {
    opacity: 0.5;
  }
}

.btn--arrow {
  min-width: 48px;
  min-height: 66px;
  position: relative;
  z-index: 20;

  .arrow-white {
    position: absolute;
    display: none;
    top: 0;
    left: 0;
    z-index: 20;

    opacity: 0;
    transition: opacity $default-transition-settings;

    &:hover,
    &:focus {
      opacity: 1;
    }
  }

  .arrow-white-up {
    position: absolute;
    display: none;
    top: 0;
    left: 0;
    z-index: 20;

    opacity: 0;
    transition: opacity $default-transition-settings;

    &:hover,
    &:focus {
      opacity: 1;
    }
  }

  .arrow-gradient {
    position: absolute;
    display: none;
    top: 0;
    left: 0;

    opacity: 0;
    transition: $default-transition-settings;

    &:hover,
    &:focus {
      opacity: 1;
    }
  }

  .arrow-white-active {
    display: block;
    opacity: 0.5;
    transition: opacity $default-transition-settings;
  }

  .arrow-gradient-active {
    display: block;
    opacity: 0.5;
    transition: opacity $default-transition-settings;
  }

  @include viewport--md {
    &.disabled {
      opacity: 0;
    }
  }
}

@keyframes fade-out-arrow {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 0.5;
  }
}

.btn--ellipse {
  position: relative;

  display: inline-block;
  vertical-align: middle;

  background-color: $color-default-white;
  border-radius: 25px;

  opacity: 1;
  transition: opacity $default-transition-settings, box-shadow $default-transition-settings;

  &:hover,
  &:focus,
  &:active {
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  }

  button {
    min-width: 239px;
    min-height: 52px;
    font-size: 24px;
    border-radius: 25px;
    opacity: 0;
    z-index: 10;
    cursor: pointer;

    // для iphone 5/se
    @media (max-width: 350px) {
      min-width: 220px;
      min-height: 45px;
      font-size: 20px;
    }

    &:hover ~ span,
    &:focus ~ span {
      background: $dark-turquoise-to-caribbean-green;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  span {
    margin: 0;
    padding: 15px 38px;

    position: absolute;
    top: 0;
    left: 0;

    min-width: 239px;
    font-size: 24px;
    background: $razzmatazz-to-purple-heart;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    pointer-events: none;

    // для iphone 5/se
    @media (max-width: 350px) {
      padding: 10px 15px;
      min-width: 220px;
      min-height: 45px;
      font-size: 20px;
    }
  }

  &.disabled {
    @include viewport--md {
      display: none;
    }
  }
}

.btn--ellipse-hidden {
  opacity: 0;
  transition: opacity $default-transition-settings;
}

.btn--border {
  position: relative;
  min-width: 189px;
  min-height: 45px;
  border-radius: 40px;

  opacity: 0.5;
  transition: opacity $default-transition-settings;
  cursor: pointer;

  // для iphone 5/se
  @media (max-width: 350px) {
    padding: 0;
    min-height: 40px;
    min-width: 170px;
    font-size: 20px;
  }

  &::before {
    position: absolute;
    top: -3px;
    bottom: -3px;
    left: -3px;
    right: -3px;
    background: $razzmatazz-to-purple-heart;
    content: "";
    z-index: -1;
    border-radius: 40px;
    cursor: pointer;
  }

  &::after {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: $color-default-white;
    content: "";
    z-index: -1;
    border-radius: 40px;
  }

  button {
    min-width: 189px;
    min-height: 45px;

    font-size: 24px;
    border-radius: 40px;
    opacity: 0;
    z-index: 10;

    // для iphone 5/se
    @media (max-width: 350px) {
      padding: 0;
      min-height: 40px;
      min-width: 170px;
      font-size: 20px;
    }
  }

  span {
    padding: 12px 31px;
    padding-top: 10px;

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    min-width: 189px;

    font-size: 24px;
    background: $razzmatazz-to-purple-heart;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    // для iphone 5/se
    @media (max-width: 350px) {
      padding: 8px 31px;
      min-height: 40px;
      min-width: 170px;
      font-size: 20px;
    }
  }

  &:hover,
  &:focus,
  &:active {
    opacity: 1;
  }
}

