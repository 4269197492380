@mixin retina {
  @media (min-resolution: $retina-dpi), (min-resolution: $retina-dppx) {
    @content;
  }
}

// Desktop
@mixin viewport--lg {
  @media (max-width: $viewport--lg - 1px) {
    @content;
  }
}

// Tablet
@mixin viewport--md {
  @media (max-width: $viewport--md - 1px) {
    @content;
  }
}

// Mobile
@mixin viewport--sm {
  @media (max-width: $viewport--sm - 1px) {
    @content;
  }
}

// keyframes

@mixin keyframes-fadeOut {
  @keyframes fade-out {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
}

@mixin keyframes-fadeIn {
  @keyframes fade-in {
    0% {
      opacity: 1;
    }

    100% {
      opacity: 0;
    }
  }
}

@mixin keyframes-transform {
  @keyframes transform-pages {
    0% {
      transform: translateY(-90px) scale(2);
    }

    100% {
      transform: translateY(0) scale(1);
    }
  }
}
