.branding {
  @include keyframes-fadeIn;
  @include keyframes-fadeOut;
  @include keyframes-transform;

  position: relative;
}

.branding__content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;

  .blocks-btn {
    @include viewport--md {
      display: inline-block;
      margin: 0 auto;
      opacity: 0;
      animation: $opacity-pages-delay-3s;
    }
  }

  .blocks-btn-disabled {
    display: none;

    @include viewport--md {
      display: inline-block;
    }
  }

  @include viewport--md {
    min-height: 457px;
  }

  @include viewport--sm {
    min-height: 336px;
  }

  .page__text {
    &--fade-in {
      animation: fade-in 2s forwards;
    }
  }
}

.branding__info {
  display: flex;
  flex-direction: column;
  width: 65%;
  margin-top: 166px;
  z-index: 2;

  @include viewport--lg {
    width: 100%;
  }

  @include viewport--md {
    flex-direction: column-reverse;
    margin-top: 86px;
  }

  @include viewport--sm {
    margin-top: 55px;
  }

  @include viewport--lg {
    @media (max-height: 699px) {
      margin-top: 86px;
    }

    @media (max-height: 599px) {
      margin-top: 55px;
    }
  }
}

.branding__text {
  width: 100%;
  margin: 0 0 28px;
  padding-left: 1%;
  padding-right: 15px;
  font-size: calc(12px + 1.06vw);
  line-height: calc(17px + 1vw);
  color: $color-default-white;

  @media (max-width: 1151px) {
    & br {
      display: none;
    }
  }

  @media (min-width: 1153px) {
    & br {
      display: none;
    }
  }

  @include viewport--lg {
    width: 68%;

    & br {
      display: none;
    }
  }

  @media (max-width: 900px) {
    width: 72%;
  }

  @include viewport--md {
    width: 100%;
    margin-top: 28px;
    margin-bottom: 39px;
    padding: 0;
    font-size: 24px;
    line-height: 28px;
    opacity: 0;
    animation: $opacity-pages;
  }

  @include viewport--sm {
    font-size: 18px;
    margin-top: 10px;
  }

  // для iphone 5/se
  @media (max-width: 350px) {
    margin-top: 25px;
    margin-bottom: 30px;
    font-size: 16px;
    line-height: 18px;
  }
}

.branding__title {
  position: absolute;
  top: calc(208px + 10vh);
  margin: 0;
  font-size: calc(94px + 2.5vh + 2.5vw);
  font-weight: 400;
  line-height: calc(119px + 6vh);
  color: $color-default-white;
  -webkit-text-stroke: 3px $color-default-white;
  text-transform: lowercase;
  z-index: 2;

  @include viewport--lg {
    @media (max-height: 699px) {
      line-height: 23vh;
      font-size: calc(22px + 16.5vh);
      top: calc(55px + 24.5vh);
    }

    @media (max-height: 599px) {
      top: calc(55px + 23.5vh);
      line-height: 20vh;
    }
  }

  @media (max-width: 1060px) {
    font-size: calc((100vw - 768px) / (1152 - 768) * (140 - 94) + 94px);
  }

  @include viewport--md {
    top: 23px;
    font-size: 48px;
    line-height: 65px;
    letter-spacing: 1.5px;
    animation: none;
  }

  @media (max-width: 400px) {
    -webkit-text-stroke: 1.5px $color-default-white;
  }

  @include viewport--sm {
    top: 0;
    font-size: 40px;
  }

  // для iphone 5/se
  @media (max-width: 350px) {
    top: 0;
    font-size: 30px;
  }
}

.branding__img {
  position: absolute;
  width: auto;
  height: 72vh;
  top: -4.2%;
  left: 38.6%;
  z-index: 1;

  @include viewport--lg {
    width: 51.3%;
    height: auto;
  }

  @media (max-width: 900px) {
    width: 465px;
    left: 40%;
  }

  @include viewport--md {
    width: 172px;
    top: 87%;
    left: 0;
    right: 0;
    margin: auto;
    opacity: 0;
    transform: translateY(-90px) scale(2);
    animation: $opacity-pages-delay-1s, $transform-pages;
  }

  // для iphone 5/se
  @media (max-width: 350px) {
    height: auto;
    top: 77%;
  }
}
