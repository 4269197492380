.footer__box {
  position: relative;

  .main-nav {
    position: absolute;
    bottom: 0;
    right: 16.3%;

    // для IPad
    @media (max-width: 850px) {
      display: none;
    }
  }
}

.main-nav {
  padding-bottom: 7px;

  min-height: 22px;
  width: 341px;

  display: flex;
  align-items: flex-end;

  @include viewport--lg {
    margin: 0;
    padding: 0;
  }
}

.main-nav__menu-desktop {
  display: block;

  @media (max-width: 850px) {
    display: none;
  }
}

.main-nav__list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  li {
    width: 100%;
  }

  a {
    display: flex;
    min-height: 22px;

    position: relative;

    span {
      position: absolute;
      display: none;
      top: 0;
      left: 0;

      min-height: 22px;

      font-size: 18px;
      line-height: 21px;

      opacity: 0.5;
      transition: opacity $default-transition-settings;

      &:hover,
      &:focus {
        opacity: 1;
      }
    }

    .white {
      color: $color-default-white;
    }

    .gradient {
      background: $razzmatazz-to-purple-heart;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    .white-bg-active {
      display: inline;
      opacity: 0.5;
      transition: $default-transition-settings;
    }

    .gradient-bg-active {
      display: inline;
      opacity: 0.5;
      transition: opacity $default-transition-settings;
    }

    .white-active-link {
      opacity: 1;
      transition: opacity $default-transition-settings;
      border-bottom: 1px solid $color-default-white;
    }

    .gradient-active-link {
      opacity: 1;
      transition: opacity $default-transition-settings;
      border-bottom: 1px solid $color-dark-magenta;
    }
  }

  // чтобы при переполнии элементы не слиплись
  li:not(:last-child) {
    margin-right: 48px;
  }

  @include viewport--lg {
    width: 280px;
  }

  @include viewport--md {
    margin-bottom: 61px;
    padding-bottom: 0;

    display: block;

    width: 100%;

    li:not(:last-child) {
      margin-bottom: 20px;
    }

    a {
      font-size: 32px;
      line-height: 45px;

      background: $razzmatazz-to-purple-heart;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;

      opacity: 1;
      transition: opacity $default-transition-settings;

      &:hover,
      &:focus,
      &:active {
        opacity: 0.5;
      }
    }
  }
}

// .main-nav__list .btn {
//   position: relative;

//   span {
//     position: absolute;
//     top: 0;
//     left: 0;

//     display: inline-block;
//     vertical-align: middle;
//     border: 1px solid black;

//     width: 100%;
//     min-height: 21px;

//     font-size: 18px;
//     line-height: 21px;
//     text-align: left;

//     opacity: 0;
//     transition: opacity $default-transition-settings;
//   }

//   .white {
//     color: $color-default-white;
//   }

//   .white-bg-active {
//     opacity: 0.5;
//   }

//   .white-link-active {
//     opacity: 1;
//     border-bottom: 1px solid $color-default-white;
//     pointer-events: none;
//   }

//   .gradient {
//     background: $razzmatazz-to-purple-heart;
//     -webkit-background-clip: text;
//     -webkit-text-fill-color: transparent;
//   }

//   .gradient-bg-active {
//     opacity: 0.5;
//   }

//   .gradient-link-active {
//     opacity: 1;
//     border-image: $razzmatazz-to-purple-heart;
//     border-image-slice: 1;
//     pointer-events: none;
//   }
// }
