/*!
 * fullPage 3.1.0
 * https://github.com/alvarotrigo/fullPage.js
 *
 * @license GPLv3 for open source use only
 * or Fullpage Commercial License for commercial use
 * http://alvarotrigo.com/fullPage/pricing/
 *
 * Copyright (C) 2018 http://alvarotrigo.com/fullPage - A project by Alvaro Trigo
 */
html.fp-enabled,
.fp-enabled body {
  margin: 0;
  padding: 0;
  overflow: hidden;

  /*Avoid flicker on slides transitions for mobile phones #336 */
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.fp-section {
  position: relative;
  -webkit-box-sizing: border-box;
  /* Safari<=5 Android<=3 */
  -moz-box-sizing: border-box;
  /* <=28 */
  box-sizing: border-box;
}

.fp-slide {
  float: left;
}

.fp-slide,
.fp-slidesContainer {
  height: 100%;
  display: block;
}

.fp-slides {
  z-index: 1;
  height: 100%;
  overflow: hidden;
  position: relative;
  -webkit-transition: height $default-transition-settings;
  /* Safari<=6 Android<=4.3 */
  transition: height $default-transition-settings;
}

.fp-section.fp-table,
.fp-slide.fp-table {
  display: table;
  table-layout: fixed;
  width: 100%;
}

.fp-tableCell {
  display: table-cell;
  vertical-align: middle;
  width: 100%;
  height: 100%;
}

.fp-slidesContainer {
  float: left;
  position: relative;
}

.fp-controlArrow {
  -webkit-user-select: none;
  /* webkit (safari, chrome) browsers */
  -moz-user-select: none;
  /* mozilla browsers */
  -khtml-user-select: none;
  /* webkit (konqueror) browsers */
  -ms-user-select: none;
  /* IE10+ */
  margin-top: -38px;
  width: 0;
  height: 0;
  position: absolute;
  top: 50%;
  z-index: 4;
  cursor: pointer;
  border-style: solid;
  -webkit-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.fp-controlArrow.fp-prev {
  left: 15px;
  width: 0;
  border-width: 38.5px 34px 38.5px 0;
  border-color: transparent $color-default-white transparent transparent;
}

.fp-controlArrow.fp-next {
  right: 15px;
  border-width: 38.5px 0 38.5px 34px;
  border-color: transparent transparent transparent $color-default-white;
}

.fp-scrollable {
  overflow: hidden;
  position: relative;
}

.fp-scroller {
  overflow: hidden;
}

.iScrollIndicator {
  border: 0 !important;
}

.fp-notransition {
  -webkit-transition: none !important;
  transition: none !important;
}

.fp-auto-height.fp-section,
.fp-auto-height .fp-slide,
.fp-auto-height .fp-tableCell {
  height: auto !important;
}

.fp-responsive .fp-auto-height-responsive.fp-section,
.fp-responsive .fp-auto-height-responsive .fp-slide,
.fp-responsive .fp-auto-height-responsive .fp-tableCell {
  height: auto !important;
}

/*Only display content to screen readers*/
.fp-sr-only {
  padding: 0;

  width: 1px;
  height: 1px;

  position: absolute;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

// пагинация (кружочки)
#fp-nav {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 140px;
  min-width: 43px;

  position: fixed;
  top: 36%;
  right: 6.5%;
  z-index: 200;

  @include viewport--md {
    display: none;
  }
}

#fp-nav ul {
  max-height: 300px;
  max-width: 24px;

  margin: 0;
  margin-bottom: 177px;
  padding: 0;
  list-style: none;

  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;

  z-index: 20;
}

#fp-nav ul li:not(:last-child) {
  margin-bottom: 46px;
}

#fp-nav ul li a {
  display: inline-block;
  width: 24px;
  height: 24px;

  background-color: transparent;
  opacity: 0.5;
  // border: 1px solid $color-default-white;
  border-radius: 50%;
  outline: none;
  transition: height $default-transition-modal, width $default-transition-modal, opacity $default-transition-settings;

  &:hover,
  &:focus,
  &:active {
    opacity: 1;
  }

  // увеличиваем кликабельную область
  &::after {
    position: absolute;
    top: 0;
    left: 0;

    content: "";
    width: 40px;
    height: 40px;
    background-color: transparent;
  }
}

.white-fp-nav-active {
  border: 1px solid $color-default-white;
}

.gradient-fp-nav-active {
  border: 1px solid $color-dark-magenta;
}

#fp-nav ul li .white-fp-nav-active.active {
  width: 10px;
  height: 10px;
  opacity: 1;
  pointer-events: none;
}

#fp-nav ul li .gradient-fp-nav-active.active {
  width: 10px;
  height: 10px;
  opacity: 1;
  pointer-events: none;
}

// #fp-nav ul li {
//   position: relative;
//   width: 24px;
//   height: 24px;
//   text-align: center;

//   .gradient-fp-nav-active {
//     position: absolute;
//     top: 0;
//     left: 0;
//     right: 0;
//     bottom: 0;

//     content: "";
//     width: 24px;
//     height: 24px;
//     background-image: url("../img/svg/circle.svg");
//     opacity: 0.5;
//     transition: opacity $default-transition-settings;
//   }

//   & .gradient-fp-nav-active.active {
//     position: absolute;
//     top: 0;
//     left: 0;
//     right: 0;
//     bottom: 0;

//     content: "";
//     width: 10px;
//     height: 10px;
//     background-image: url("../img/svg/circle.svg");
//     background-size: 10px 10px;
//     pointer-events: none;
//     opacity: 1;
//     transition: opacity $default-transition-settings;
//   }
// }
