.services {
  @include keyframes-fadeIn;
  @include keyframes-fadeOut;

  position: relative;
  background: $razzmatazz-to-purple-heart;

  display: flex;

  opacity: 0;
  animation: fade-out 1s forwards;
  transition: $default-transition-settings;

  .container__content {
    min-height: 637px;

    @include viewport--md {
      min-height: 457px;
    }

    @include viewport--sm {
      min-height: 336px;
    }
  }

  .container__content--disabled {
    position: absolute;
    opacity: 1;
    animation: fade-in 3s forwards;
    transition: $default-transition-settings;
  }

  .container__content--fade-out {
    position: absolute;
    animation: fade-out 3s forwards;
  }

  .animation-logo-short {
    display: none;

    @include viewport--md {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;

      display: inline-block;
      vertical-align: middle;

      width: 48px;
      height: 48px;

      opacity: 0;

      animation-name: disappears-short-logo;
      animation-duration: 2s;
    }
  }

  .footer {
    @include viewport--md {
      opacity: 0;
      animation: coming-footer 2s 3s forwards;
    }
  }
}

.services__content {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.services__list {
  display: flex;
  flex-direction: column;
  margin: -6vh 0 20px;
  padding: 0;
  list-style: none;

  @include viewport--md {
    margin-bottom: 34px;
    padding-top: 42.8vh;

    opacity: 0;
    animation: transform-logo 2s 3s forwards;
  }

  @include viewport--sm {
    padding-top: 29.8vh;
  }
}

.services__item {
  position: relative;
  display: inline-block;
}

.services__item-btn {
  position: relative;
  display: inline-block;
  padding: 0;
  cursor: pointer;
  outline: none;
  background-color: $color-default-transparent;
  border: none;
}

.services__item-span {
  font-size: calc(91px + 2.5vh + 2.5vw);
  font-weight: 400;
  line-height: calc(119px + 6vh);
  text-transform: lowercase;
  letter-spacing: 2px;
  pointer-events: none;
  transition: $default-transition-settings;

  &--reg {
    -webkit-text-stroke: calc(2px + 0.01vh + 0.01vw) $color-default-white;
    color: $color-default-transparent;

    @include viewport--md {
      -webkit-text-stroke: 1.5px $color-default-white;
    }
  }

  @media (max-height: 699px) {
    line-height: 23vh;
    font-size: calc(22px + 16.5vh);
  }

  @media (max-height: 579px) {
    line-height: 20vh;
  }

  &--hover {
    position: absolute;
    left: 0;
    top: 0;
    color: $color-default-white;
    -webkit-text-stroke: calc(3px + 0.01vh + 0.01vw);
    opacity: 0;
    transition: $default-transition-settings;

    @include viewport--md {
      -webkit-text-stroke: 1.5px $color-default-white;
    }
  }

  @media (max-width: 1060px) {
    font-size: calc((100vw - 768px) / (1152 - 768) * (140 - 94) + 94px);
  }

  @include viewport--md {
    font-size: calc((100vw - 375px) / (767 - 375) * (58 - 48) + 48px);
    line-height: calc(45px + 2vh + 2vw);
    -webkit-text-stroke: none;
    letter-spacing: 1.5px;
    color: $color-default-white;
  }

  // шрифт уменьшен,чтобы при ширине экрана 320px текст не вываливался за край
  @include viewport--sm {
    font-size: 42px;
  }

  // для iphone 5/se
  @media (max-width: 320px) {
    font-size: 38px;
    line-height: 55px;
  }
}

.services__item-btn--active .services__item-span--hover,
.services__item-btn:focus .services__item-span--hover {
  opacity: 1;
}

@keyframes disappears-short-logo {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes coming-footer {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 0.5;
  }
}
