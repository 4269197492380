.text-confident {
  display: none;
  width: 348px;

  @include viewport--md {
    max-width: 248px;
    font-size: 14px;
  }
}

.text-confident--active {
  display: block;
}

.text-confident__info {
  margin: 0;
  padding: 0;

  font-size: 16px;
  line-height: 18px;

  background: $razzmatazz-to-purple-heart;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  @include viewport--md {
    font-size: 14px;
  }

  &:first-child {
    margin-bottom: 10px;
  }
}
