.contacts {
  position: relative;
  background: $razzmatazz-to-purple-heart;
  transition: background $default-transition-settings;

  .footer {
    padding-left: 44%;
  }

  @include viewport--md {
    .header {
      display: none;
    }

    .footer .arrow-control {
      display: none;
    }
  }

  .btn--ellipse {
    max-width: 239px;
  }

  .container__content {
    transform: translateY(-45px);

    @include viewport--md {
      transform: translateY(0);
    }
  }
}

.contacts__content {
  display: flex;
  flex-direction: column;
}

.contacts__text {
  margin: 17vh 0 33px;

  font-size: calc(94px + 2.5vh + 2.5vw);
  line-height: calc(119px + 6vh);
  -webkit-text-stroke: 3px $color-default-white;
  word-wrap: break-word;
  color: $color-default-white;

  @media (max-width: 1060px) {
    font-size: calc((100vw - 768px) / (1152 - 768) * (140 - 94) + 94px);
  }

  @include viewport--md {
    margin-top: 21.4vh;
    margin-bottom: 55px;
    font-size: calc((100vw - 375px) / (767 - 375) * (58 - 48) + 48px);
    line-height: calc(45px + 2vh + 2vw);
    letter-spacing: 1.5px;
  }

  // шрифт уменьшен,чтобы при ширине экрана 320pxтекст не вываливался за край
  @include viewport--sm {
    font-size: 42px;
  }

  // для android
  @media (max-width: 374px) {
    margin-top: 11%;
  }

  // для iphone 5/se
  @media (max-width: 320px) {
    margin-top: 11%;
    margin-bottom: 20px;
    font-size: 38px;
    line-height: 40px;
  }
}

.contacts__btn {
  margin-bottom: 36px;

  @include viewport--md {
    margin: 0 auto;
    margin-bottom: 61px;
  }

  @include viewport--sm {
    margin-bottom: 62px;

    display: flex;
    justify-content: center;
  }

  // для android
  @media (max-width: 373px) {
    margin-bottom: 30px;
  }

  // для iphone 5/se
  @media (max-width: 320px) {
    margin-bottom: 20px;
  }
}

.contacts__mail {
  max-width: 100%;

  @include viewport--lg {
    margin-bottom: 9px;
  }

  @include viewport--sm {
    max-width: 90%;
    margin-bottom: 9px;
  }
}

.contacts__mail-text,
.contacts__link {
  margin: 0 0 10px;

  font-size: calc(4px + 1.09vh + 1.08vw);
  line-height: calc(10px + 1vh + 1vw);
  color: $color-default-white;

  @include viewport--md {
    margin-bottom: 0;
    font-size: 24px;
    line-height: 36px;
  }

  @media (max-width: 489px) {
    width: 77%;
  }

  @include viewport--sm {
    width: 100%;
    font-size: 22px;
  }

  // для iphone 5/se
  @media (max-width: 320px) {
    font-size: 22px;
    line-height: 26px;
  }
}

.contacts__link {
  cursor: pointer;
  transition: opacity $default-transition-settings;

  &:hover,
  &:focus {
    opacity: 0.5;
  }

  &:active {
    opacity: 1;
    border-bottom: 1px solid #ffffff;
  }
}
