.modal-confident {
  display: none;
}

.modal-confident.active {
  //- Чтобы окно не прилипало к границе браузера
  padding: 30px 0;

  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;

  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;

  z-index: 1150;
}

.modal-confident__content {
  padding: 46px 64px;

  position: relative;

  max-height: 411px;
  width: 527px;

  display: flex;
  flex-direction: column;

  background: $color-default-white;
  box-shadow: 5px 15px 50px rgba(0, 0, 0, 0.25);
  z-index: 300;

  overflow-y: auto;

  @include viewport--md {
    padding: 16px 24px;
    width: 310px;
  }

  .modal-confident__btn {
    position: absolute;
    top: 15px;
    right: 11px;

    @include viewport--md {
      width: 32px;
      height: 32px;

      &::before {
        width: 32px;

        top: 15px;
        left: 0;
      }

      &::after {
        width: 32px;

        top: 15px;
        left: 0;
      }
    }
  }
}

.modal-confident__title {
  margin: 0;
  margin-bottom: 31px;
  padding: 0;

  font-size: 18px;
  line-height: 21px;

  background: $razzmatazz-to-purple-heart;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.modal-confident__info {
  margin: 0;
  margin-bottom: 21px;
  padding: 0;

  max-width: 348px;

  font-size: 16px;
  line-height: 18px;

  background: $razzmatazz-to-purple-heart;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  @include viewport--md {
    max-width: 248px;
    font-size: 14px;
  }
}

.modal-confident__link {
  padding: 0;
  margin-bottom: 10px;

  min-height: 41px;
  width: 200px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  opacity: 1;
  transition: opacity $default-transition-settings;

  @include viewport--md {
    width: 150px;
  }

  &:hover,
  &:active,
  &:focus {
    opacity: 0.5;
  }

  p {
    padding: 0;
    margin: 0;

    font-size: 36px;
    line-height: 41px;

    background: $razzmatazz-to-purple-heart;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    @include viewport--md {
      font-size: 25px;
    }
  }

  svg {
    margin-top: 14px;
  }
}
