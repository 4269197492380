.modal-project {
  // padding-top: 0;
  // padding-bottom: 48px;

  display: flex;
  flex-direction: column;

  position: fixed;
  top: 0;
  right: 0;

  width: 100%;
  max-width: 100%;
  height: 100vh;
  min-height: calc(100 * var(--vh, 1vh));

  background-color: $color-default-white;

  z-index: 1000;

  transform: translateX(100%);
  transition: transform $default-transition-modal;
  transition-delay: 0.3s;
}

.modal-project.active {
  // padding-top: 0;
  // padding-bottom: 48px;

  display: flex;
  flex-direction: column;

  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;

  width: 100%;
  max-width: 100%;
  height: 100vh;
  min-height: calc(100 * var(--vh, 1vh));
  background-color: $color-default-white;

  transform: translateX(0);
  transition: transform $default-transition-modal;
  transition-delay: 0.3s;

  @include viewport--md {
    padding-bottom: 48px;
  }
}

.modal-project__wrapper {
  position: relative;
  padding: 0 72px;
  padding-left: 97px;

  @include viewport--lg {
    padding: 0 70px;
  }

  @include viewport--md {
    padding: 0 25px;
  }

  // для ios
  overflow-y: auto;

  .modal-project__btn-close {
    position: absolute;
    top: 2.5%;
    left: 17px;

    width: 52px;
    height: 52px;

    background: transparent;

    @include viewport--lg {
      left: 88%;
    }

    @include viewport--md {
      top: 3.4%;
      left: 88%;

      width: 32px;
      height: 32px;

      &::before {
        width: 32px;

        top: 15px;
        left: 0;
      }

      &::after {
        width: 32px;

        top: 15px;
        left: 0;
      }
    }

    @media (max-width: 400px) {
      left: 82%;
    }
  }
}

.modal-project__logo-wrapper {
  padding-top: 20px;
  padding-bottom: 5px;

  display: flex;

  @include viewport--lg {
    display: none;
  }
}

.modal-project__logo {
  max-width: 215px;
  margin-left: auto;

  display: inline-block;
  vertical-align: middle;

  @include viewport--md {
    display: none;
  }
}

.modal-project__content {
  margin-top: -27px;

  display: flex;
  flex-direction: column;

  .modal-project__content-title {
    margin: 0;
    margin-bottom: 52px;
    padding: 0;
    max-width: 600px;

    font-size: 84px;
    line-height: 97px;
    font-weight: 400;
    text-transform: lowercase;

    background: $razzmatazz-to-purple-heart-no-transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: $color-purple-heart;

    @include viewport--lg {
      margin-top: 26px;
      margin-bottom: 47px;

      font-size: 64px;
      line-height: 68px;
    }

    @include viewport--md {
      max-width: 215px;
      // для ios
      margin-bottom: 47px;

      font-size: 32px;
      line-height: 45px;
    }

    // для iphone 5/se
    @media (max-width: 350px) {
      margin-bottom: 20px;
    }
  }
}

.modal-project__form {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  text-transform: lowercase;

  @include viewport--lg {
    flex-direction: column;
  }
}

.modal-project__btn-box {
  margin-bottom: 50px;

  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  @include viewport--lg {
    margin-bottom: 16px;
  }
}

.modal-project__btn {
  margin-top: 3px;

  @include viewport--md {
    margin-top: 12px;
  }
}

.modal-project__agreement-text {
  margin-top: 24px;

  opacity: 0.5;
  transition: opacity $default-transition-settings;

  @include viewport--lg {
    margin-top: 14px;
    width: 100%;
    text-align: left;
  }

  &:hover,
  &:focus,
  &:active {
    opacity: 1;
  }

  .modal-project__text {
    margin: 0;
    padding: 0;

    width: 99%;
    font-size: 16px;
    line-height: 18px;

    background: $razzmatazz-to-purple-heart;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: $color-purple-heart;

    @include viewport--lg {
      width: 100%;
    }

    // для iphone 5/se
    @media (max-width: 350px) {
      margin-bottom: 30px;
      width: 100%;
      font-size: 12px;
      line-height: 14px;
    }
  }

  a {
    font-weight: 700;
  }
}
