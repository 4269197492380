.modal-success {
  display: none;

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  width: 100%;
  max-width: 100%;
  height: 100vh;
  min-height: calc(100 * var(--vh, 1vh));

  background-color: $color-default-white;
}

.modal-success.active {
  display: block;

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  width: 100%;
  max-width: 100%;
  height: 100vh;
  min-height: calc(100 * var(--vh, 1vh));

  background-color: $color-default-white;
  z-index: 1000;
}

.modal-success__content {
  position: relative;
  display: flex;
  flex-direction: column;

  width: 100%;
  max-width: 100%;
  height: 100vh;
  min-height: calc(100 * var(--vh, 1vh));

  .modal-success__btn-close {
    position: absolute;
    top: 19px;
    left: 19px;

    @include viewport--sm {
      width: 32px;
      height: 32px;

      top: 19px;
      left: 82%;

      &::before {
        width: 32px;

        top: 15px;
        left: 0;
      }

      &::after {
        width: 32px;

        top: 15px;
        left: 0;
      }
    }
  }

  .modal-success__img {
    position: absolute;
    bottom: 0;
    right: 35px;

    @media (max-width: 1100px) {
      width: 208px;
      height: 539px;
    }

    @media (max-width: 890px) {
      width: 174px;
      height: 480px;
    }

    @include viewport--md {
      right: 38%;

      width: 104px;
      height: 262px;
    }
  }
}

.modal-success__logo {
  padding: 20px 0;
  max-width: 215px;
  margin-left: auto;

  display: inline-block;
  vertical-align: middle;

  @include viewport--md {
    display: none;
  }
}

.modal-success__text-wrap {
  padding-top: 132px;

  @include viewport--md {
    padding-top: 187px;
  }
}

.modal-success__text {
  margin: 0;
  padding: 0;

  width: 753px;

  font-size: 84px;
  line-height: 97px;
  background: $razzmatazz-to-purple-heart;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-transform: lowercase;

  @media (max-width: 1100px) {
    width: 578px;

    font-size: 64px;
    line-height: 80px;
  }

  @media (max-width: 890px) {
    width: 578px;

    font-size: 54px;
    line-height: 70px;
  }

  @include viewport--md {
    font-size: 36px;
    line-height: 41px;
  }

  @media (max-width: 350px) {
    width: 478px;

    font-size: 30px;
    line-height: 40px;
  }
}
