.sites {
  @include keyframes-fadeIn;
  @include keyframes-fadeOut;
  @include keyframes-transform;

  position: relative;
}

.sites__content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 637px;

  .blocks-btn {
    @include viewport--md {
      display: inline-block;
      margin: 0 auto;
      opacity: 0;
      animation: $opacity-pages-delay-3s;
    }
  }

  .blocks-btn-disabled {
    display: none;

    @include viewport--md {
      display: inline-block;
    }
  }

  .page__title {
    transform: translateY(-157px);
    animation: translate-sites-title 5s forwards;

    @include viewport--md {
      transform: translateY(0);
      animation: none;
    }

    &--fade-in {
      transform: translateY(0);
      animation: translate-sites-title-2 4s forwards;
    }
  }

  .page__text {
    animation: fade-out 1s 2s forwards;

    &--fade-in {
      animation: fade-in 2s forwards;
    }
  }

  @include viewport--md {
    min-height: 457px;
  }

  @include viewport--sm {
    min-height: 336px;
  }
}

@keyframes translate-sites-title {
  0% {
    transform: translateY(-157px);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes translate-sites-title-2 {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-157px);
  }
}

.sites__info {
  display: flex;
  flex-direction: column;

  @include viewport--md {
    flex-direction: column-reverse;
    margin-top: 88px;
  }

  @include viewport--sm {
    margin-top: 55px;
  }
}


.sites__text {
  width: 49%;
  margin-top: 6px;
  padding-left: 1%;
  padding-right: 15px;
  font-size: calc(12px + 1.06vw);
  line-height: calc(17px + 1vw);
  color: $color-default-white;
  opacity: 0;

  @media (max-width: 900px) {
    width: 56%;
  }

  @include viewport--md {
    width: 100%;
    margin-top: 26px;
    margin-bottom: 39px;
    padding: 0;
    font-size: 24px;
    line-height: 28px;
  }

  @include viewport--sm {
    font-size: 18px;
    margin-top: 10px;
  }

  // для iphone 5/se
  @media (max-width: 350px) {
    margin-top: 25px;
    margin-bottom: 30px;
    font-size: 16px;
    line-height: 18px;
  }
}

.sites__title {
  position: absolute;
  top: calc(87px + 4vh);
  margin: 0;
  font-size: calc(94px + 2.5vh + 2.5vw);
  line-height: calc(119px + 6vh);
  font-weight: 400;
  color: $color-default-white;
  -webkit-text-stroke: 3px $color-default-white;
  text-transform: lowercase;
  z-index: 2;

  @include viewport--lg {
    @media (max-height: 699px) {
      line-height: 23vh;
      font-size: calc(22px + 16.5vh);
    }

    @media (max-height: 579px) {
      line-height: 20vh;
    }
  }

  @media (max-width: 1060px) {
    font-size: calc((100vw - 768px) / (1152 - 768) * (140 - 94) + 94px);
  }

  @include viewport--md {
    top: 23px;
    font-size: calc((100vw - 375px) / (767 - 375) * (58 - 48) + 48px);
    line-height: calc(45px + 2vh + 2vw);
    letter-spacing: 0.5px;
  }

  @media (max-width: 400px) {
    -webkit-text-stroke: 1.5px $color-default-white;
  }

  @include viewport--sm {
    top: 0;
    font-size: 40px;
  }

  // для iphone 5/se
  @media (max-width: 350px) {
    top: 0;
    font-size: 30px;
  }
}

.sites__img {
  position: absolute;
  width: auto;
  height: 65vh;
  top: 5.5%;
  left: 43.2%;
  z-index: 1;
  animation: fade-out 1s forwards;

  @include viewport--lg {
    width: 46.5%;
    height: auto;
    top: 6.7%;
  }

  @media (max-width: 900px) {
    width: 410px;
    left: 40%;
  }

  @include viewport--md {
    width: 194px;
    top: 82%;
    left: 0;
    right: 0;
    margin: auto;
    transform: translateY(-90px) scale(2);
    animation: $opacity-pages-delay-1s, $transform-pages;
  }

  // для iphone 5/se
  @media (max-width: 350px) {
    top: 75%;
  }
}
