.about-us {
  position: relative;
  background: $color-default-white;

  .container__content {
    transform: translateY(-87px);

    @include viewport--md {
      transform: translateY(-67px);
    }
  }

  .footer {
    padding-left: 44%;
  }

  @include viewport--md {
    .header {
      display: none;
    }

    .footer .arrow-control {
      display: none;
    }
  }
}

.about-us__content {
  display: flex;
  flex-direction: column;
}

.about-us__text {
  margin: 14px 0 59px 8px;
  width: 71%;
  font-size: calc(12px + 1.08vh + 1.08vw);
  line-height: calc(8px + 2vh + 2vw);

  background: $razzmatazz-to-purple-heart;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  @include viewport--md {
    width: 98%;
    max-width: 620px;
    margin: 0;
    margin-top: 0;
    margin-bottom: 17px;

    font-size: 32px;
    line-height: 45px;
  }

  @include viewport--sm {
    font-size: 24px;
  }

  &-mobile {
    display: none;

    @include viewport--md {
      display: inline;
    }
  }

  // для iphone 5/se
  @media (max-width: 350px) {
    font-size: 22px;
    line-height: 26px;
  }
}

.about-us__box {
  position: relative;

  .about-us__marquee-wrap {
    position: absolute;
    min-height: 86px;
    bottom: -100px;
    --move-initial: -2%;
    --move-final: -24.2%;
  }
}

.about-us__marquee-inner-wrap {
  position: absolute;
  width: fit-content;
  display: flex;

  top: 0;
  left: 0;
  overflow: hidden;

  &.is-active {
    will-change: transform;
    animation: move 8s linear infinite reverse;
  }

  @include viewport--md {
    top: -55px;
  }
}

@keyframes move {
  0% {
    transform: translateX(var(--move-initial));
  }

  100% {
    transform: translateX(var(--move-final));
  }
}

.about-us__marquee {
  white-space: nowrap;
  margin-right: 22px;
  margin-left: 22px;

  font-size: 140px;
  line-height: 161px;

  -webkit-text-stroke: 2px $color-default-transparent;
  background: $razzmatazz-to-purple-heart;
  -webkit-background-clip: text;

  @include viewport--lg {
    font-size: calc((100vw - 768px) / (1152 - 768) * (140 - 94) + 94px);
  }

  @include viewport--md {
    font-size: 140px;
  }

  &:nth-of-type(odd) {
    -webkit-text-fill-color: $color-default-white;
  }

  &:nth-of-type(even) {
    -webkit-text-fill-color: $color-default-transparent;
  }
}
